<template>
  <div class="task-form">
    <div class="field">
      <label for="">Add to</label>
      <v-select
        label="nickname"
        :options="teachers"
        v-model="item.teacher_ids"
        :reduce="item => item.id"
        autocomplete="on"
        clearable
        placeholder="Select teacher"
        :closeOnSelect="false"
        multiple
      >
        <template #option="{ nickname, options }">
          <vs-avatar
            :src="
              options && options.avatar
                ? `${proxy}/static/files/${options.avatar}`
                : undefined
            "
          ></vs-avatar
          >{{ nickname }}
        </template>
        <template #selected-option="{ nickname, options }">
          <vs-avatar
            size="20px"
            :src="
              options && options.avatar
                ? `${proxy}/static/files/${options.avatar}`
                : undefined
            "
          ></vs-avatar>
          {{ nickname }}
        </template>
        <div v-if="!$v.item.teacher_ids.minLength" class="error">
          Field is required
        </div>
      </v-select>
    </div>
    <div class="field">
      <vs-input
        v-model="item.name"
        label="Name"
        max-length="150"
        size="large"
      ></vs-input>
      <div v-if="!$v.item.name.required" class="error">Field is required</div>
    </div>
    <div class="field">
      <label for="">Description</label>
      <quill-editor
        ref="myQuillEditor"
        v-model="item.desc"
        :options="editorOption"
      />
    </div>
    <div class="date-range">
      <div class="field">
        <label for="">Start date</label>
        <v-date-picker v-model="item.date_start" mode="dateTime" is24hr>
          <template v-slot="{ inputValue, inputEvents }">
            <div>
              <vs-input
                class="calendar-input"
                :value="`${inputValue}`"
                v-on="inputEvents"
                size="large"
              />
            </div>
          </template>
        </v-date-picker>
        <vs-checkbox
          class="lesson__check-all-day"
          v-model="item.all_day"
          v-if="place_type == 'event'"
          >All day</vs-checkbox
        >
      </div>
      <div class="field" v-if="place_type != 'event'">
        <label for="">Finish date</label>
        <v-date-picker v-model="item.date_finish" mode="dateTime" is24hr>
          <template v-slot="{ inputValue, inputEvents }">
            <div>
              <vs-input
                class="calendar-input"
                :value="`${inputValue}`"
                v-on="inputEvents"
                size="large"
              />
            </div>
          </template>
        </v-date-picker>
      </div>
      <div class="field" v-else-if="place_type == 'event' && !item.all_day">
        <label for="">Duration, minutes</label>
        <vs-input type="number" v-model="item.duration" size="large"></vs-input>
      </div>
    </div>
    <div v-if="place_type == 'event'">
      <vs-checkbox class="lesson__check-repeat" v-model="item.repeat"
        >Repeat event</vs-checkbox
      >
      <div class="event__repeat" v-if="item.repeat">
        <div class="repeat__option">
          <div class="field">
            <label for="">Repeat every</label>
            <vs-input
              v-model="item.rrules.interval"
              class="repeat-interval"
            ></vs-input>
          </div>
          <div class="field">
            <label for="">Period</label>
            <vs-select v-model="item.rrules.freq">
              <vs-select-item value="DAILY" text="day" />
              <vs-select-item value="WEEKLY" text="week" />
              <vs-select-item value="MONTHLY" text="month" />
              <vs-select-item value="YEARLY" text="year" />
            </vs-select>
          </div>
        </div>
        <div class="repeat__option">
          <label for="">Until</label>
          <section>
            <vs-radio
              v-model="item.untilType"
              vs-name="untilType"
              vs-value="month"
              >End of {{ $moment(now).format('MMMM') }}</vs-radio
            >
            <vs-radio
              v-model="item.untilType"
              vs-name="untilType"
              vs-value="year"
              >End of shool year</vs-radio
            >
            <vs-radio
              v-model="item.untilType"
              vs-name="untilType"
              vs-value="custom"
              >Custom date</vs-radio
            >
            <vs-radio
              v-model="item.untilType"
              vs-name="untilType"
              vs-value="unlim"
              >Unlimited</vs-radio
            >
            <v-date-picker
              v-if="item.untilType == 'custom'"
              v-model="item.rrules.until"
              mode="date"
            />
          </section>
        </div>
        <div class="repeat__option field">
          <label for="">Total count to create</label>
          <vs-input size="large" v-model="item.rrules.count"></vs-input>
        </div>
      </div>
      <div class="field">
        <label for="">Color</label>
        <twitter-picker
          v-model="item.color"
          :default-colors="[
            '#FF6900',
            '#FCB900',
            '#7BDCB5',
            '#00D084',
            '#8ED1FC',
            '#0693E3',
            '#ABB8C3',
            '#EB144C',
            '#F78DA7',
            '#9900EF',
            '#fff',
          ]"
          width="320px"
        />
      </div>
      <div class="field notifications">
        <div class="notifications__header">
          <h3>Notifications ({{ item.notifications.length }})</h3>
          <vs-button
            icon="add"
            size="small"
            type="border"
            @click="addNotification"
            >Add</vs-button
          >
        </div>
        <div
          class="notification"
          v-for="(n, index) in item.notifications"
          :key="index"
        >
          <div class="field">
            before:
          </div>
          <div class="field">
            <vs-input v-model="n.value" @change="changeNotification"></vs-input>
          </div>
          <div class="field">
            <vs-select
              v-model="n.units"
              size="small"
              @change="changeNotification"
            >
              <vs-select-item value="minutes" text="Minutes" />
              <vs-select-item value="hours" text="Hours" />
              <vs-select-item value="days" text="Days" />
              <vs-select-item value="weeks" text="Weeks" />
            </vs-select>
          </div>
          <div class="field">
            <vs-button
              icon="close"
              size="small"
              type="flat"
              color="dark"
              title="Delete notification"
              @click="deleteNotification(index)"
            ></vs-button>
          </div>
        </div>
      </div>
    </div>
    <div class="field">
      <label for="">Importance</label>
      <v-select
        label="name"
        :options="importance"
        v-model="item.importance"
        :reduce="item => item.id"
        autocomplete="on"
        placeholder="Select importance"
      >
        <template #selected-option="{ name, icon, color }">
          <vs-icon :icon="icon" :color="color"></vs-icon>
          {{ name }}
        </template>
        <template #option="{ name, icon, color }">
          <vs-icon :icon="icon" :color="color"></vs-icon>
          {{ name }}
        </template>
      </v-select>
      <div v-if="!$v.item.importance.required" class="error">
        Field is required
      </div>
    </div>
    <div class="field" v-if="place_type == 'lesson'">
      <label for="">Task add to lesson:</label>
      {{ lesson.name }}
    </div>
    <div class="field" v-if="place_type != 'event'">
      <label for="">Group</label>
      <v-select
        label="name"
        :options="groups"
        v-model="item.group_id"
        :reduce="item => item.id"
        autocomplete="on"
        clearable
        placeholder="Select group"
        :closeOnSelect="true"
        menu-props="auto"
        :disabled="groupDisable"
      >
        <template #option="{ color, name }">
          <vs-avatar icon="people" :color="color"></vs-avatar>
          {{ name }}
        </template>
        <template #selected-option="{ color, name }">
          <vs-avatar icon="people" :color="color" size="20px"></vs-avatar>
          {{ name }}
        </template>
      </v-select>
    </div>
    <div class="field" v-if="place_type != 'event'">
      <label for="">Students</label>
      <v-select
        label="name"
        :options="customStudents"
        v-model="item.student_ids"
        :reduce="item => item.id"
        autocomplete="on"
        clearable
        multiple
        placeholder="Select student"
        :closeOnSelect="true"
        menu-props="auto"
      >
        <template #option="{ name }">
          <vs-avatar icon="person"></vs-avatar>
          {{ name }}
        </template>
        <template #selected-option="{ name }">
          <vs-avatar icon="person" size="20px"></vs-avatar>
          {{ name }}
        </template>
      </v-select>
    </div>

    <div class="task-form__actions save-popup-button">
      <vs-button title="Save" icon="save" color="primary" @click="save">
        Save
      </vs-button>
    </div>
    <vs-prompt color="danger" @accept="acceptAlert" :active.sync="activePrompt">
      <div class="con-exemple-prompt">
        Change this event with:
        <!-- <vs-radio v-model="item.change_with" vs-name="change_with" vs-value="3"
          >All events
        </vs-radio> -->
        <vs-radio v-model="item.change_with" vs-name="change_with" vs-value="2"
          >Next events</vs-radio
        >
        <vs-radio v-model="item.change_with" vs-name="change_with" vs-value="1"
          >Only this event</vs-radio
        >
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import vSelect from 'vue-select'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { TASK_IMPORTANCE } from './dicts'
import { quillEditor } from 'vue-quill-editor'
import { required, minLength } from 'vuelidate/lib/validators'
import { Twitter } from 'vue-color'

// type:
// group - при добавлении из группы
// lesson - при добавлении из занятия
// student - при добавлении из ученика
// event - событие

export default {
  props: {
    task: {
      type: Object,
    },
    place_type: {
      type: String,
      validator: function(value) {
        // Значение должно соответствовать одной из этих строк
        return (
          ['teacher', 'group', 'lesson', 'student', 'event'].indexOf(value) !==
          -1
        )
      },
    },
    place_id: {
      type: Number,
    },
    lesson: {
      type: Object,
    },
    group: {
      type: Object,
    },
    date_start: {
      type: Number,
      default: new Date().getTime(),
    },
  },
  data() {
    const user = this.$getUser()
    const {
      id = null,
      name = '',
      desc = '',
      importance = null,
      date_start = this.date_start / 1000,
      date_finish = null,
      event = null,
    } = this.task || {}

    // значения по умолчанию
    let student_ids = this.place_type == 'student' ? [this.place_id] : []
    let group_id = this.place_type == 'group' ? this.place_id : null
    let lesson_id = null
    let teacher_ids = this.place_type == 'teacher' ? [this.place_id] : [user.id]
    let rrules = { freq: 'DAILY', count: null, interval: 1, until: null }
    let duration = 60
    let parent_id = null
    let all_day = false
    let notifications = []

    if (this.place_type == 'lesson') {
      lesson_id = this.place_id
      group_id = this.lesson.group.id
      teacher_ids = [this.lesson.main_teacher.id]
    }
    if (this.place_type == 'group') {
      teacher_ids = this.group.lessons_time.reduce((list, t) => {
        if (!list.includes(t.teacher)) {
          list.push(t.teacher)
        }
        return list
      }, [])
    }
    // переписываем значениями из задачи для редактирования
    if (this.task) {
      teacher_ids = this.task.teachers.map(t => t.id)
      student_ids = this.task.students.map(s => s.id) || []
      group_id = this.task.group.id || null
      lesson_id = this.task.lesson.id || null
      parent_id = this.task.event?.parent?.id || null
      rrules = event?.parent?.rrules || rrules
      rrules.until = rrules.until ? new Date(rrules.until * 1000) : new Date()
      all_day = event?.all_day
      notifications = this.task.notifications
    }
    const groupDisable = Boolean(
      this.place_type == 'group' || this.place_type == 'lesson'
    )

    return {
      proxy: process.env.VUE_APP_PROXY,
      user,
      editorOption: {
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ color: [] }, { background: [] }],
            ['clean'],
          ],
          // keyboard: {
          //   bindings,
          // },
        },
      },
      importance: TASK_IMPORTANCE,
      groupDisable,
      activePrompt: false,
      now: new Date(),
      notificationTemplate: {
        seconds: 15 * 60, // 15 минут
        units: 'minutes',
        value: 15,
      },
      item: {
        name,
        desc,
        importance: importance == null ? 1 : importance,
        teacher_ids,
        group_id,
        student_ids,
        lesson_id,
        parent_id,
        id,
        date_start: date_start ? new Date(date_start * 1000) : null,
        date_finish: date_finish ? new Date(date_finish * 1000) : null,
        event,
        color: event?.color || '#fff',
        rrules,
        duration: this.task?.event?.duration || duration,
        repeat: id ? true : false, // для сохраненнных событий по умолчиню нет повтора (будут включать принудительно)
        untilType: rrules.until ? 'custom' : 'year',
        change_with: 1, // this event
        all_day,
        notifications,
      },
    }
  },
  validations: {
    item: {
      name: {
        required,
      },
      importance: {
        required,
      },
      teacher_ids: {
        minLength: minLength(1),
      },
    },
  },
  methods: {
    save: async function() {
      const data = this.item
      data.date_start = this.$moment(data.date_start).format('YYYY-MM-DD HH:mm')
      // data.date_start = data.date_start ? data.date_start.getTime() : null
      // data.date_finish = data.date_finish ? data.date_start.getTime() : null
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$vs.notify({
          title: 'Error',
          text: `Required fields is empty`,
          color: 'danger',
          position: 'top-right',
        })
        return
      }
      if (this.place_type == 'event') {
        if (data.id) this.activePrompt = true
        else this.acceptAlert()
      } else {
        this.$store
          .dispatch('tasks/saveTaskAction', { data })
          .then(() => {
            this.$emit('taskSaved')
            this.$vs.notify({
              title: 'Task saved!',
              text: ``,
              color: 'success',
              position: 'top-right',
            })
          })
          .catch(e => {
            this.$vs.notify({
              title: 'Task not saved!',
              text: `${e}`,
              color: 'danger',
              position: 'top-right',
            })
          })
      }
    },
    acceptAlert: function() {
      const data = this.item
      data.date_start = this.$moment(data.date_start).format('YYYY-MM-DD HH:mm')
      data.color = data.color.hex
      let rrules = data.rrules
      let until
      // если без повтора, то делаем одиночное событие
      if (!data.repeat) rrules.count = 1
      // если меняем существующее, но без повтора
      if (this.id && !data.repeat) rrules = {}
      // вычилсяем до какого числа повтор
      if (data.untilType != 'unlim') {
        switch (data.untilType) {
          case 'month':
            until =
              this.$moment()
                .endOf('month')
                .unix() / 1000
            break
          case 'year':
            until = this.currentYear.date_end_unix
            break
          case 'custom':
            console.log(rrules.until)
            until = rrules.until.getTime() / 1000
        }
        rrules.until = until
      }

      this.$store
        .dispatch('events/saveEventAction', { data })
        .then(() => {
          this.$emit('taskSaved')
          this.$vs.notify({
            title: 'Event saved!',
            text: ``,
            color: 'success',
            position: 'top-right',
          })
        })
        .catch(e => {
          this.$vs.notify({
            title: 'Event not saved!',
            text: `${e}`,
            color: 'danger',
            position: 'top-right',
          })
        })
    },
    addNotification: function() {
      this.item.notifications.push(Object.assign({}, this.notificationTemplate))
    },
    deleteNotification: function(index) {
      this.item.notifications.splice(index, 1)
    },
    changeNotification: function() {
      let notifications = this.item.notifications.reduce((output, n) => {
        if (n.value < 1) {
          n.value = 1
        }
        if (n.units == 'minutes') {
          if (n.value > 60 * 24 * 7 * 2) {
            n.value = 60 * 24 * 7 * 2
          }
          n.seconds = n.value * 60
        } else if (n.units == 'hours') {
          if (n.value > 24 * 7 * 2) {
            n.value = 24 * 7 * 2
          }
          n.seconds = n.value * 60 * 60
        } else if (n.units == 'days') {
          if (n.value > 7 * 2) {
            n.value = 7 * 2
          }
          n.seconds = n.value * 60 * 60 * 24
        } else if (n.units == 'weeks') {
          if (n.value > 2) {
            n.value = 2
          }
          n.seconds = n.value * 60 * 60 * 24 * 7
        }
        n.value = parseInt(n.value, 10)
        output.push(n)
        return output
      }, [])
      console.log(notifications)
      this.item.notifications = notifications
    },
  },
  computed: {
    ...mapState({
      teachers: state => state.teachers.teachers,
      groups: state => state.groups.groups,
      students: state => state.students.students,
      currentYear: state => state.years.currentSchoolYear,
    }),
    customStudents: function() {
      if (this.item.group_id) {
        let group = this.groups.find(g => g.id == this.item.group_id)
        if (group) {
          return group.students.filter(s => s.is_active) || this.students
        }
      }
      return this.students
    },
  },
  components: {
    vSelect,
    quillEditor,
    'twitter-picker': Twitter,
  },
  beforeMount: async function() {
    const schoolyear_id = window.localStorage.getItem('schoolyearId')
    await this.$store.dispatch('groups/getGroupsAction', {
      data: {
        schoolyear_id,
      },
    })
    if (!this.students.length) {
      await this.$store.dispatch('students/getStudentsAction')
    }
  },
}
</script>

<style lang="scss">
.task-form {
  height: 100%;
  .vs-input {
    width: 100%;
  }
  .ql-editor {
    min-height: 5rem !important;
  }
  .repeat__interval {
    display: flex;
    flex-flow: row nowrap;
    gap: 2rem;
    & > div {
      align-items: flex-start;
    }
  }
  .field {
    &.notifications {
      background-color: #f1f1f1;
      padding: 1rem;
      border-radius: 5px;
    }
    .vs-button {
      float: none;
      margin-left: 1rem;
    }
  }
  .notifications {
    &__header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
    }
    .notification {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      div:nth-child(2) {
        flex: 1;
        margin-right: 0.5rem;
      }
      .con-select {
        margin-top: 0;
        .vs-select--input {
          padding: 7px;
        }
      }
    }
  }
}
</style>
